import React from 'react'
import readingTime from 'reading-time'
import styled from 'styled-components'
import { Link } from 'gatsby'
import BlogPostCard from '../templates/prismic/blog-post-card'
import { Button } from './UI'

const Container = styled.div`
    display: grid;
    grid-template-columns: 205px 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 30px;
    border-bottom: 2px solid;
    border-bottom-color: ${(props) => props.theme.color.tellowPurpleLight};
    padding-bottom: 40px;
    margin-top: 48px;

    @media screen and (max-width: 965px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        padding-bottom: 20px;
        border-bottom-width: 1px;
        margin-top: 24px;
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }

    &:last-child {
        border-bottom: unset;
    }
`

const Wrapper = styled.div`
    &:first-child {
        grid-row: 1 / 3;
        grid-column: 1 / 2;

        @media screen and (min-width: 600px) and (max-width: 965px) {
            grid-row: 1 / 2;
            grid-column: 1 / 3;
        }
    }
`

const Header = styled.h2`
    font-size: 20px;
    line-height: 29px;
    margin-bottom: 10px;

    @media screen and (max-width: 965px) {
        margin-bottom: 6px;
    }
`

const Description = styled.p`
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
`

const CategoryButton = styled(Button)`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.color.tellowPurple};
    background-color: white;
    padding: 0;
`

const Category = (props) => {
    const aboutCategory = [
        {
            tagName: 'Over Tellow',
            description: "Dé ideale boekhoudapp voor zzp'ers. Alles wat je te weten wil komen over het bedrijf, de organisatie en meer.",
        },
        {
            tagName: 'Boekhoudtips',
            description: 'Handige tips die jou kunnen helpen bij je boekhouding.',
        },
        {
            tagName: 'Financieel advies',
            description: 'Zit je met vragen over specifieke  onderwerpen en wil je meer weten komen over financiën? Lees hier alles over financieel advies.',
        },
        {
            tagName: 'Freelancer van de maand',
            description: "Lees hier de verhalen van ervaren zzp'ers en opkomende ondernemers. Word jij de volgende freelancer van de maand?",
        },
        {
            tagName: 'Ondernemerschap',
            description: 'Belangrijke need-to-knows voor jou als ondernemer over hoe je bedrijf kan laten groeien en schalen; het ondernemerschap en meer...',
        },
        {
            tagName: 'Privacy',
            description: "Van papier naar cloud: Hoe online boekhoudprogramma's jouw gegevens veilig bewaren. Lees het hier.",
        },
        {
            tagName: 'Coronavirus',
            description: 'Lees hier alles over hoe COVID-19 invloed heeft gehad op de economie, conjunctuur, financiële sector en meer...',
        },
    ]

    const handleClick = (tag) => {
        props.setPage(tag)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }

    return (
        <Container>
            <Wrapper>
                <Header>{props.title}</Header>
                {aboutCategory.map(
                    (el) =>
                        props.title === el.tagName && (
                            <span key={el.tagName}>
                                <Description key={el.tagName}>{el.description}</Description>
                                <CategoryButton onClick={() => handleClick(el.tagName)}>Zoek alle&nbsp; →</CategoryButton>
                            </span>
                        )
                )}
            </Wrapper>

            {props.data.map(({ node }) => {
                const image = node.data.headerimage
                return (
                    <Wrapper key={node.id}>
                        <Link to={`/blog/${node.uid}`}>
                            <BlogPostCard
                                small
                                fluid={image.fluid}
                                previewImgAlt={image.alt || node.data.title.text}
                                title={node.data.title.text}
                                lastPublicationDate={new Date(node.data.post_date) || new Date(node.datePublished)}
                                readingTime={readingTime(node.data.content.text).text.replace('min read', 'min leestijd')}
                                paddingRight="0"
                                metaColor="#9DA7B3"
                                withDot
                                category
                            />
                        </Link>
                    </Wrapper>
                )
            })}
        </Container>
    )
}

export default Category
