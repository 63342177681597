import React from 'react'
import readingTime from 'reading-time'
import styled from 'styled-components'
import { Link } from 'gatsby'
import BlogPostCard from '../templates/prismic/blog-post-card'
import { excerptResolver, truncate } from '../utils/prismic/excerptResolver'

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 110px);
    grid-gap: 3rem;
    border-bottom: 2px solid;
    border-bottom-color: ${(props) => props.theme.color.tellowPurpleLight};

    @media screen and (max-width: 1100px) {
        grid-gap: 1rem;
    }

    @media screen and (min-width: 600px) and (max-width: 820px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(2, 1fr);
        padding-bottom: 20px;
        border-bottom-width: 1px;
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        grid-gap: 2rem 0;
        padding-bottom: 20px;
    }
`

const Card = styled.div`
    grid-column: 3 / 5;

    @media screen and (max-width: 1100px) {
        grid-column: 2 / 5;
    }

    @media screen and (min-width: 600px) and (max-width: 820px) {
        grid-column: unset;
    }

    @media screen and (max-width: 600px) {
        grid-column: 1 / 5;
    }

    &:first-child {
        grid-column: 1 / 3;
        grid-row: 1 / 5;
        border-right: 2px solid;
        border-right-color: ${(props) => props.theme.color.tellowPurpleLight};

        @media screen and (max-width: 1100px) {
            grid-column: 1 / 2;
        }

        @media screen and (min-width: 600px) and (max-width: 820px) {
            border-right: unset;
            grid-column: unset;
            grid-row: unset;
        }

        @media screen and (max-width: 600px) {
            display: none;
            visibility: hidden;
        }
    }
`

const CardLink = styled(Link)`
    &:first-child {
        display: contents;
    }
`

const RecentBlock = (props) => (
    <Container>
        {props.data &&
            props.data.slice(0, 4).map(({ node }, index) => {
                const image = node.data.headerimage
                return (
                    <Card key={node.id}>
                        <CardLink to={`/blog/${node.uid}`}>
                            <BlogPostCard
                                large={index === 0}
                                small={index !== 0}
                                fluid={image.fluid}
                                previewImgAlt={image.alt || node.data.title.text}
                                tag={node.data.tags[0].tag.document.data.tag_name}
                                title={node.data.title.text}
                                description={index === 0 && truncate(excerptResolver(node.data))}
                                lastPublicationDate={new Date(node.data.post_date) || new Date(node.datePublished)}
                                readingTime={readingTime(node.data.content.text).text.replace('min read', 'min leestijd')}
                                paddingRight="0"
                                metaColor="#9DA7B3"
                                withDot
                            />
                        </CardLink>
                    </Card>
                )
            })}
    </Container>
)

export default RecentBlock
