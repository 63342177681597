import React, { useState } from 'react'
import styled from 'styled-components'
import { slugify } from '../utils/slugify'
import { BlogHero } from '../pages/_blog.styles'
import { Button } from './UI'
import { TextContainer } from '../utils'
import BlogHomePage, { desiredTagOrder } from './blogHomePage'
import BlogsByTag from './blogsByTag'

const Wrapper = styled.div`
    padding-top: 73px;
    padding-bottom: 54px;

    @media screen and (max-width: 590px) {
        padding-top: 30px;
        padding-bottom: 8px;
    }
`

const Header = styled.h1`
    text-align: center;
    font-size: 36px;
    line-height: 54px;
`

const Description = styled.p`
    text-align: center;
    font-size: 20px;
    line-height: 28px;

    @media screen and (max-width: 425px) {
        font-size: 16px;
        line-height: 24px;
    }
`

const ButtonsWrapper = styled.div`
    text-align: center;

    @media screen and (max-width: 992px) {
        display: none;
        visibility: hidden;
    }
`

const TagButton = styled(Button)`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 10px 25px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: unset;
    transition: all ease-in-out 150ms;

    @media screen and (max-width: 1191px) {
        padding: 8px 12px;
    }

    ${({ active }) =>
        active &&
        `
        opacity: 1 !important;
        background-color: white;
        color: #5950ec;
        text-shadow: 0 0 0.5px #5950ec, 0 0 0.5px #5950ec, 0 0 0.5px #5950ec;
    `}

    &:hover {
        background-color: white;
        color: ${(props) => props.theme.color.tellowPurple};
        transition: all ease-in-out 150ms;
        /* text-shadow: 0 0 0.5px #5950ec, 0 0 0.5px #5950ec, 0 0 0.5px #5950ec; */
    }
`

const StyledTextContainer = styled(TextContainer)`
    ${({ withoutPadding }) =>
        withoutPadding &&
        `
        @media screen and (min-width: 1100px) {
            padding-top: 0 !important;
        }
    }
  `}
`

const BlogHeader = React.forwardRef((props, ref) => {
    const [showPage, setShowPage] = useState('all')

    const sortedTagList = props.tagList.sort((a, b) => desiredTagOrder.indexOf(a.data.tag_name) - desiredTagOrder.indexOf(b.data.tag_name))

    return (
        <>
            <BlogHero ref={ref}>
                <Wrapper>
                    <Header>Wij (boek)houden van financiën</Header>
                    <Description>Alle weetjes vind je in onze blogs</Description>
                </Wrapper>

                <ButtonsWrapper>
                    <TagButton onClick={() => setShowPage('all')} active={showPage === 'all'}>
                        Alle
                    </TagButton>

                    {sortedTagList.map((node) => {
                        const tag = node.data.tag_name
                        const slug = slugify(node.data.tag_name)

                        return (
                            <TagButton key={slug} tag={slug} onClick={() => setShowPage(tag)} active={showPage === tag}>
                                {tag}
                            </TagButton>
                        )
                    })}
                </ButtonsWrapper>
            </BlogHero>

            {showPage === 'all' && (
                <StyledTextContainer className="without-hero-block">
                    <BlogHomePage blockHeader="Recentste artikelen" data={props.data} showPage={showPage} setPage={setShowPage} />
                </StyledTextContainer>
            )}

            {showPage !== 'all' && (
                <StyledTextContainer className="without-hero-block" withoutPadding>
                    <BlogsByTag tag={showPage} data={props.data} setPage={setShowPage} />
                </StyledTextContainer>
            )}
        </>
    )
})

BlogHeader.displayName = 'BlogHeader'

export default BlogHeader
