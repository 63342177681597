import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactPaginate from 'react-paginate'

const StyledPaginateContainer = styled.div`
    .container {
        display: flex;
        justify-content: center;
        margin-top: 96px;

        @media screen and (max-width: 630px) {
            margin-top: 40px;
        }
    }

    .page {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0;
        padding: 0 9px;
    }

    .link {
        color: ${(props) => props.theme.color.tellowBlack};
        cursor: pointer;
    }

    .active {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0;
    }

    .activeLink {
        color: ${(props) => props.theme.color.tellowPurple};
    }

    .previous,
    .next {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        cursor: pointer;
        padding: 0 20px;
    }

    .disabled {
        color: ${(props) => props.theme.color.tellowBlack};
        opacity: 0.5;
        cursor: default;
    }

    .break {
        padding: 0 9px;
        line-height: 17px;
    }
`

const Pagination = (props) => {
    const pageCount = Math.ceil(props.itemsCount / props.itemsPerPage)
    const [currentPage, setCurrentPage] = useState(0)

    const handlePageClick = (event) => {
        setCurrentPage(event.selected)
        props.onPaginationClick(event.selected * props.itemsPerPage, (event.selected + 1) * props.itemsPerPage)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }

    useEffect(() => {
        setCurrentPage(0)
    }, [props.tag])
    return (
        props.itemsCount > props.itemsPerPage && (
            <StyledPaginateContainer>
                <ReactPaginate
                    nextLabel="Volgende&nbsp;>"
                    previousLabel="<&nbsp;Vorige"
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    breakLabel="..."
                    breakClassName="break"
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={0}
                    renderOnZeroPageCount={null}
                    activeClassName="active"
                    activeLinkClassName="activeLink"
                    containerClassName="container"
                    pageClassName="page"
                    pageLinkClassName="link"
                    previousClassName="previous"
                    nextClassName="next"
                    disabledClassName="disabled"
                    forcePage={currentPage}
                />
            </StyledPaginateContainer>
        )
    )
}

export default Pagination
