import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Category from './category'
import RecentBlock from './recentBlock'

const BlockHeader = styled.h1`
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 41px;

    ${({ withTopMargin }) =>
        withTopMargin &&
        `
        margin-top: 47px;

        @media screen and (max-width: 965px) {
            margin-top: 32px;
        }
    }
  `}
`

export const desiredTagOrder = ['Boekhoudtips', 'Financieel advies', 'Ondernemerschap', 'Over Tellow', 'Freelancer van de maand', 'Privacy', 'Coronavirus']

const BlogHomePage = (props) => {
    const [blogByTag, setBlogByTag] = useState({})

    useEffect(() => {
        const res = {}
        props.data.forEach((el) => {
            const tagName = el.node.data.tags[0].tag.document.data.tag_name
            const exists = Object.keys(res).includes(tagName)
            if (!exists) {
                res[tagName] = [el]
            } else if (res[tagName].length < 4) {
                res[tagName] = [...res[tagName], el]
            }
        })
        setBlogByTag(res)
    }, [])

    return (
        <>
            <BlockHeader>{props.blockHeader}</BlockHeader>
            <RecentBlock data={props.data} />

            <BlockHeader withTopMargin>Zoek per categorie</BlockHeader>
            {desiredTagOrder.map((tagName) => {
                if (blogByTag[tagName]) {
                    const entry = [tagName, blogByTag[tagName]]

                    return <Category key={entry[0]} uid={entry[0]} title={entry[0]} data={entry[1]} setPage={props.setPage} />
                }

                return null
            })}
        </>
    )
}

export default BlogHomePage
