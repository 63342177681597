import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Date } from 'prismic-reactjs'
import readingTime from 'reading-time'
import BlogPostCard from '../templates/prismic/blog-post-card'
import { excerptResolver, truncate } from '../utils/prismic/excerptResolver'
import Pagination from '../templates/prismic/pagination'
import { Button } from './UI'
import Badge from '../templates/prismic/badge'

const Container = styled.div`
    padding-top: 60px;

    @media screen and (max-width: 1024px) {
        padding-top: 30px;
    }

    @media screen and (max-width: 768px) {
        padding-top: 40px;
    }
`

const PageHeader = styled.div`
    display: none;
    visibility: hidden;

    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.color.tellowGray800};

    @media screen and (max-width: 985px) {
        display: flex;
        visibility: unset;
        justify-items: flex-start;
        align-items: center;
        gap: 0.25rem;
        margin-bottom: 1.5rem;
    }
`

const BlogButton = styled(Button)`
    color: ${(props) => props.theme.color.tellowPurple};
    background-color: ${(props) => props.theme.color.tellowWhite};
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    border: unset;
    padding: unset;
`

const Slash = styled.span`
    padding: 0 6px;
`

const TagTitle = styled.h1`
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.color.tellowGray800};
    font-weight: 400;
    margin-bottom: 0;
`

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 350px;
    grid-gap: 1.75rem;

    @media screen and (max-width: 950px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 630px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const BlogsByTag = (props) => {
    const filterTag = props.tag
    const itemsPerPage = 12

    const [blogByTag, setBlogByTag] = useState([])
    const [paginatedItems, setPaginatedItems] = useState([])

    const onPaginationClick = (start, end) => {
        setPaginatedItems(blogByTag.slice(start, end))
    }

    useEffect(() => {
        const res = []
        props.data.forEach((el) => {
            const tagName = el.node.data.tags[0].tag.document.data.tag_name
            if (tagName !== filterTag) {
                return
            }
            res.push(el)
        })
        setBlogByTag(res)
        setPaginatedItems(res.slice(0, Math.min(res.length, itemsPerPage)))
    }, [filterTag])

    return (
        <>
            <Container>
                <PageHeader>
                    <BlogButton onClick={() => props.setPage('all')}>
                        <Badge tag="Blogs" purple />
                    </BlogButton>
                    <Slash>&#8725;</Slash>
                    <TagTitle>{props.tag}</TagTitle>
                </PageHeader>

                <Wrapper>
                    {paginatedItems &&
                        paginatedItems.map(({ node }) => {
                            const image = node.data.headerimage
                            return (
                                <Link to={`/blog/${node.uid}`} key={node.id}>
                                    <BlogPostCard
                                        fluid={image.fluid}
                                        previewImgAlt={image.alt}
                                        tag={filterTag}
                                        title={node.data.title.text}
                                        description={truncate(excerptResolver(node.data))}
                                        lastPublicationDate={Date(node.data.post_date) || Date(node.datePublished)}
                                        readingTime={readingTime(node.data.content.text).text.replace('min read', 'mins read')}
                                        metaColor="#9DA7B3"
                                        paddingRight="0"
                                        withDot
                                    />
                                </Link>
                            )
                        })}
                </Wrapper>

                <Pagination tag={filterTag} itemsCount={blogByTag.length} itemsPerPage={itemsPerPage} onPaginationClick={onPaginationClick} />

                <BlogButton onClick={() => props.setPage('all')} style={{ marginTop: '40px', fontWeight: 500 }}>
                    &#8592; Terug naar alle
                </BlogButton>
            </Container>
        </>
    )
}

export default BlogsByTag
