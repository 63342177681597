import { graphql } from 'gatsby'
import React from 'react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'
import Meta from '../components/layout/meta'
import { reviewStructuredData } from '../components/layout/meta/schema-org'
import BlogHeader from '../components/BlogHeader'

const BlogListPage = ({ data }) => (
    <Layout>
        <Meta
            keywords={['nieuws', 'zzp', 'boekhouden']}
            title="Tellow blog - Nieuws voor jou als zzp'er"
            description="Nieuws voor jou als zzp'er"
            path="/blog"
            jsonLD={reviewStructuredData}
        />

        <BlogHeader tagList={data.allPrismicTag.nodes} data={data.allPrismicBlog.edges} style={{ backgroundColor: '#5950ec' }} />
    </Layout>
)

export default withPrismicPreview(BlogListPage)

export const query = graphql`
    query BlogPage {
        allPrismicBlog(sort: { order: DESC, fields: [data___post_date] }, filter: { lang: { eq: "nl-nl" } }) {
            edges {
                node {
                    id
                    uid
                    _previewable
                    lang
                    alternate_languages {
                        id
                        type
                        lang
                        uid
                    }
                    data {
                        title {
                            text
                        }
                        content {
                            text
                        }
                        excerpt
                        usepostdate
                        meta_description
                        post_date
                        tags {
                            tag {
                                document {
                                    ... on PrismicTag {
                                        id
                                        _previewable
                                        data {
                                            tag_name
                                        }
                                    }
                                }
                            }
                        }
                        headerimage {
                            alt
                            fluid {
                                src
                                aspectRatio
                                base64
                                # sizes (deprecated)
                                srcSetWebp
                                srcSet
                                srcWebp
                            }
                            url
                        }
                    }
                    datePublished: last_publication_date
                }
            }
        }
        allPrismicTag(filter: { lang: { eq: "nl-nl" } }) {
            nodes {
                _previewable
                lang
                alternate_languages {
                    id
                    type
                    lang
                    uid
                }
                data {
                    tag_name
                }
            }
        }
    }
`
